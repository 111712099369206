:root {
    --yellow: rgba(218,172,55,255);
    --blue: rgba(34,41,60,255);
}
@media screen and (min-width: 420px) {
    .App {
        width: 100%;
        min-height: 97vh;
        height: fit-content;
        color: var(--blue);
    }

    .Footer {
        display: flex;
        height: 3vh;
        background-color: var(--blue);
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .Footer a {
        color: var(--yellow);
    }

    .Footer p {
        margin-right: 17.5rem;
        color: var(--yellow);
    }

    .Nav {
        display: flex;
        justify-content: space-around;
        align-items: center;
        min-height: fit-content;
        background-color: var(--blue);
    }

    .Nav p {
        font-size: 2rem;
        padding: 0.7rem;
        color: var(--yellow);
    }

    .nav-links {
        width: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        list-style: none;
    }

    .link {
        color: var(--yellow);
        font-size: 1rem;
        text-decoration: none;
    }

    .Home {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .carousel-root-normal {
        max-width: 80%;
        margin-top: 2rem;
    }

    .carousel-root-mobile {
        display: none;
    }

    .home-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        width: 80vw;
    }

    .home-content h1 {
        margin-bottom: 2rem;
    }

    .home-content a {
        margin-bottom: 2rem;
        max-width: 20vw;
    }

    .Books {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: fit-content;
        margin-bottom: 1rem;
    }

    .warning {
        font-size: 2rem;
        padding: 3rem;
    }

    .Book {
        display: flex;
        justify-content: center;
        border-bottom: 0.3rem solid var(--blue);
    }

    .book-grid-container {
        display: grid;
        width: 80vw;
        gap: 10px;
        padding: 10px;
        box-sizing: inherit;
    }

    .book-grid-item {
        text-align: center;
        padding: 20px;
        font-size: 30px;
    }

    .book-item1 {
        all: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row: 1;
        max-width: 50vw;
    }

    .book-item1-img {
        all: unset;
        max-width: 100%;
        max-height: auto;
    }

    .book-item2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-column-start: 4;
        grid-column-end: 8;
        grid-row: 1;
    }
 

    .book-title {
        font-size: 2.5rem;
    }

    .book-author {
        font-size: 1rem;
        padding: 1rem;
    }

    .book-description {
        font-size: 1rem;
        text-align: left;
    }

    .incentives {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }
    .buyButton{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }

    .preview-btn {
        background-color: var(--blue);
        color: var(--yellow);
        border: 0.2rem solid var(--blue);
        border-radius: 0.5rem;
        height: 4rem;
        width: fit-content;
        font-size: 1.5rem;
        margin-right: 2.5rem;
        padding: 0.25rem;
    }
    
    .preview-btn:hover {
        background-color: transparent;
        background-color: var(--yellow);
        color: var(--blue);
        cursor: pointer;
    }

    .amazon-link {
        display: block;
        width: 40%;
        min-width: 40%;
        height: auto;
    }

    .amazon-img {
        all: unset;
        display: block;
        width: 100%;
        height: auto;
    }

    .book-popup-button {
        all: unset;
        width: 100%;
        height: fit-content;
        cursor: pointer;
    }

    .book-popup-button:hover {
        all: unset;
        cursor: pointer;
    }

    .pdf-controls {
        display: flex;
        flex-direction: row;
        width: 50vw;
        align-items: center;
        justify-content: center;
    }

    .pdf-controls p {
        font-size: 0.9rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .pdf-controls button {
        background-color: var(--yellow);
        color: var(--blue);
        border: 0.2rem solid var(--yellow);
        border-radius: 0.5rem;
        height: 2rem;
        width: 3rem;
        font-size: 0.75rem;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }

    .pdf-controls button:hover {
        background-color: transparent;
        background-color: var(--blue);
        color: var(--yellow);
        cursor: pointer;
    }

    img {
        display: block;
        width: 90%;
        height: auto;
    }

    .popup-box {
        position: fixed;
        background: #00000050;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
    }

    .box {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        width: 70%;
        margin: 0 auto;
        height: auto;
        max-height: 90vh;
        margin-top: calc(100vh - 92.5vh - 20px);
        background: #fff;
        border-radius: 4px;
        padding: 20px;
        border: 1px solid #999;
        overflow: auto;
    }

    .close-icon {
        content: 'x';
        cursor: pointer;
        position: fixed;
        right: calc(15% - 30px);
        top: calc(100vh - 94vh - 33px);
        background: #ededed;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #999;
        font-size: 20px;
    }

    .stories {
        display: flex;
        width: 98vw;
        height: fit-content;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .About {
        display: flex;
        justify-content: center;
    }

    .grid-container {
        display: grid;
        width: 80vw;
        gap: 10px;
        padding: 10px;
    }

    .grid-item {
        text-align: center;
        padding: 20px;
        font-size: 30px;
    }
    
    .item1 {
        display: flex;
        align-items: center;
        grid-column: 1 / span 7;
        grid-row: 1;
    }

    .item1 p {
        font-size: 1rem;
    }

    .item1 a {
        font-size: 1rem;
        text-decoration: none;
        color: rgb(139, 96, 209);
    }

    .item2 {
        display: flex;
        grid-column: 8 / span 5;
        grid-row: 1;
        align-items: center;
    }

    button {
        background-color: var(--blue);
        color: var(--yellow);
        border: 0.2rem solid var(--blue);
        border-radius: 0.5rem;
        height: 2rem;
        width: fit-content;
        font-size: 1rem;
        margin-left: 1rem;
        margin-top: 1rem;
        padding: 0.25rem;
    }
    
    button:hover {
        background-color: transparent;
        background-color: var(--yellow);
        color: var(--blue);
        cursor: pointer;
    }

    input {
        height: 2rem;
        width: 60%;
        margin-top: 1rem;
        border: 0.2rem solid var(--blue);
        border-radius: 0.5rem;
        padding: 0.25rem;
        background-color: var(--blue);
        color: var(--yellow);
        font-size: 1rem;
    }

    .Lore {
        display: flex;
        width: 100%;
        height: 75vh;
        justify-content: center;
        align-items: center;
    }

    .Lore p {
        font-size: 4rem;
    }
}

@media screen and (max-width: 420px) {
    .App {
        width: 100%;
        min-height: 93vh;
        height: fit-content;
        color: var(--blue);
    }
    
    .Footer {
        display: flex;
        flex-direction: column;
        height: 7vh;
        background-color: var(--blue);
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
    .Footer a {
        color: var(--yellow);
    }
    
    .Footer p {
        color: var(--yellow);
    }
    
    .Nav {
        display: flex;
        justify-content: space-around;
        align-items: center;
        min-height: fit-content;
        background-color: var(--blue);
    }
    
    .Nav p {
        font-size: 1.5rem;
        padding: 0.7rem;
        margin-left: 0.2rem;
        color: var(--yellow);
    }
    
    .nav-links {
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        list-style: none;
    }
    
    .link {
        color: var(--yellow);
        font-size: 1rem;
        text-decoration: none;
        margin-left: 0.4rem;
    }
    
    .Home {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    
    .carousel-root-normal {
        display: none;
    }

    .carousel-root-mobile {
        width: 80%;
        margin-top: 2rem;
    }

    .home-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        width: 100vw;
    }
    
    .Books {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: fit-content;
        margin-bottom: 1rem;
    }
    
    .warning {
        font-size: 2rem;
        padding: 3rem;
    }
    
    .Book {
        display: flex;
        justify-content: center;
        border-bottom: 0.3rem solid var(--blue);
    }
    
    .book-grid-container {
        display: grid;
        width: 80vw;
        gap: 10px;
        padding: 10px;
        box-sizing: inherit;
    }
    
    .book-grid-item {
        text-align: left;
        padding: 20px;
        font-size: 30px;
    }
    
    .book-item1 {
        all: unset;
        grid-column: 1;
        grid-row: 1;
        max-width: 90vw;
    }
    
    .book-item1-img {
        all: unset;
        max-width: 100%;
        max-height: auto;
    }
    
    .book-item2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-column: 1;
        grid-row: 2;
    }
    
    .book-title {
        font-size: 2.5rem;
    }
    
    .book-author {
        font-size: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    
    .book-description {
        font-size: 1rem;
        text-align: left;
    }
    
    .incentives {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }
    
    .preview-btn {
        background-color: var(--blue);
        color: var(--yellow);
        border: 0.2rem solid var(--blue);
        border-radius: 0.5rem;
        height: 4rem;
        width: fit-content;
        font-size: 1.5rem;
        padding: 0.25rem;
      }
      
    .preview-btn:hover {
        background-color: transparent;
        background-color: var(--yellow);
        color: var(--blue);
        cursor: pointer;
    }
    
    .amazon-link {
        margin-top: 2rem;
        display: block;
        width: 70%;
        min-width: 40%;
        height: auto;
    }
    
    .amazon-img {
        all: unset;
        display: block;
        width: 100%;
        height: auto;
    }
    
    .book-popup-button {
        all: unset;
        width: 100%;
        height: fit-content;
        cursor: pointer;
    }
    
    .book-popup-button:hover {
        all: unset;
        cursor: pointer;
    }
    
    .pdf-controls {
        display: flex;
        flex-direction: row;
        width: 50vw;
        align-items: center;
        justify-content: center;
    }
    
    .pdf-controls p {
        font-size: 0.9rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }
    
    .pdf-controls button {
        background-color: var(--yellow);
        color: var(--blue);
        border: 0.2rem solid var(--yellow);
        border-radius: 0.5rem;
        height: 2rem;
        width: 3rem;
        font-size: 0.75rem;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }
    
    .pdf-controls button:hover {
        background-color: transparent;
        background-color: var(--blue);
        color: var(--yellow);
        cursor: pointer;
    }

    img {
        display: block;
        width: 90%;
        height: auto;
    }
    
    .popup-box {
        position: fixed;
        background: #00000050;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
    }
    
    .box {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        width: 70%;
        margin: 0 auto;
        height: auto;
        max-height: 90vh;
        margin-top: calc(100vh - 92.5vh - 20px);
        background: #fff;
        border-radius: 4px;
        padding: 20px;
        border: 1px solid #999;
        overflow: auto;
    }
    
    .close-icon {
        content: 'x';
        cursor: pointer;
        position: fixed;
        right: calc(15% - 30px);
        top: calc(100vh - 94vh - 33px);
        background: #ededed;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #999;
        font-size: 20px;
    }
    
    .About {
        display: flex;
        justify-content: center;
    }
    
    .grid-container {
        display: grid;
        width: 80vw;
        gap: 10px;
        padding: 10px;
    }
    
    .grid-item {
        text-align: center;
        padding: 20px;
        font-size: 30px;
      }
      
    .item1 {
        display: flex;
        align-items: center;
        grid-column: 1;
        grid-row: 2;
    }
    
    .item1 p {
        font-size: 1rem;
    }
    
    .item2 {
        display: flex;
        grid-column: 1;
        grid-row: 1;
        align-items: center;
    }
    
    button {
        background-color: var(--blue);
        color: var(--yellow);
        border: 0.2rem solid var(--blue);
        border-radius: 0.5rem;
        height: 2rem;
        width: fit-content;
        font-size: 1rem;
        margin-left: 1rem;
        margin-top: 1rem;
        padding: 0.25rem;
      }
      
    button:hover {
        background-color: transparent;
        background-color: var(--yellow);
        color: var(--blue);
        cursor: pointer;
    }
    
    input {
        height: 2rem;
        width: 60%;
        margin-top: 1rem;
        border: 0.2rem solid var(--blue);
        border-radius: 0.5rem;
        padding: 0.25rem;
        background-color: var(--blue);
        color: var(--yellow);
        font-size: 1rem;
    }

    .Lore {
        display: flex;
        width: 100%;
        height: 75vh;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .Lore p {
        font-size: 4rem;
    }

    .stories {
        display: flex;
        width: 98vw;
        height: fit-content;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.react-pdf__Page {
    border: 2px solid black;
}